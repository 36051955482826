import { VisuallyHidden } from '@/components/visually-hidden';
import { tv } from '@/utils/styles';

interface Props {
  htmlFor: string;
  children: React.ReactNode[] | React.ReactNode | string;
  isHidden?: boolean;
  variant?: 'light' | 'dark';
  alignment?: 'vertical' | 'horizontal';
  isOptional?: boolean;
  className?: string;
}

const Label = ({
  htmlFor,
  children,
  isHidden,
  alignment = 'vertical',
  variant = 'light',
  isOptional,
  className,
  ...props
}: Props) => {
  const { base, inner } = labelStyles({ variant, alignment });

  const renderLabel = () => (
    <label htmlFor={htmlFor} {...props} className={base({ class: className })}>
      {children}
      {isOptional && <span className={inner()}>(Optional)</span>}
    </label>
  );

  return isHidden ? <VisuallyHidden data-testid="visually-hidden">{renderLabel()}</VisuallyHidden> : renderLabel();
};

const labelStyles = tv({
  slots: {
    base: 'flex items-center text-sm font-medium uppercase leading-snug',
    inner: 'ml-1 font-normal normal-case text-grey-400',
  },
  variants: {
    variant: {
      light: 'text-grey-600',
      dark: 'text-grey-400',
    },
    alignment: {
      vertical: 'mb-1 pl-1',
      horizontal: 'min-w-32 pr-2',
    },
  },
});

export { Label };
