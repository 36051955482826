import { SpaceProps } from 'styled-system';

import { Text } from '@/components/text';
import { cn } from '@/utils/styles';

interface Props extends SpaceProps {
  children: React.ReactNode | string;
  className?: string;
}

const Error = ({ children, className, ...props }: Props) => {
  return (
    <div className={cn('mt-2', className)} {...props}>
      <Text variant="secondary" className="mx-2 my-0 font-semibold">
        {children}
      </Text>
    </div>
  );
};

export { Error };
